<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId=='billDetails'"
      :class="modalState?'show':''"
      tabindex="-1"
      role="dialog"
      style="display: block;"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal_ac_head modal-md" role="document">
        <div class="modal-content">
          <div class="bg-theam-secondary">
            <div class="modal-body">
              <div class="row">
                <div class="col-md-2">
                  <img :src="thumb" class="img-fluid" />
                </div>
                <div class="col-md-8">
                  <h4 class="title font-weight-bold text-center">Softechpark Pvt. Ltd.</h4>
                  <p class="text-center font-weight-bold">Tinkune-32, Kathmandu, Nepal</p>
                  <p
                    class="text-center font-weight-bold"
                  >Tel No: +977 01-41119992 / info@softechpark.com</p>
                  <p class="text-center font-weight-bold">PAN : {{details.payment_detail.order.pan}}</p>
                </div>
                <div class="col-md-12">
                  <div class="line"></div>
                </div>
              </div>
              <div class="row font-weight-bold">
                <div class="col-md-12">BILLED TO</div>
                <div class="col-md-6">
                  NAME :
                  <span class="text-capitalize">{{details.payment_detail.order.user.name}}</span>
                </div>
                <div class="col-md-6 text-right">
                  INVOICE NUMBER :
                  <span class="text-uppercase">softinv{{details.id}}</span>
                </div>
                <div class="col-md-6">
                  ADDRESS :
                  <span class="text-capitalize">{{details.payment_detail.order.address}}</span>
                </div>
                <div class="col-md-6 text-right">
                  SUBSCRIPTION ID :
                  <span
                    class="text-uppercase"
                  >spn{{details.payment_detail.order.subscriptions.id}}</span>
                </div>
                <div class="col-md-6">
                  PAN :
                  <span class="text-capitalize">{{details.payment_detail.order.pan}}</span>
                </div>
                <div class="col-md-6 text-right">
                  ISSUE DATE :
                  <span class="text-uppercase">{{details.bill_date}}</span>
                </div>
                <div class="col-md-12 my-4">
                  <table class="table">
                    <thead>
                      <tr style="border-top: 2px solid #888888;">
                        <th scope="col">SN.</th>
                        <th scope="col">DESCRIPTION</th>
                        <th scope="col">QNTY</th>
                        <th scope="col">UNIT PRICE</th>
                        <th scope="col">AMOUNT</th>
                      </tr>
                    </thead>
                    <tbody style="font-weight: 500;">
                      <tr>
                        <td>1</td>
                        <td>{{details.payment_detail.order.plan.title}} ({{details.payment_detail.order.subscriptions.title}})</td>
                        <td>1</td>
                        <td>{{details.payment_detail.order.plan.price}}</td>
                        <td>{{details.payment_detail.order.plan.price * details.payment_detail.order.subscription}}</td>
                      </tr>
                      <tr
                        v-if="details.payment_detail.order.branches>details.payment_detail.order.plan.branches"
                      >
                        <td>2</td>
                        <td>Extra Branch Added ({{details.payment_detail.order.subscriptions.title}})</td>
                        <td>{{details.payment_detail.order.branches-details.payment_detail.order.plan.branches}}</td>
                        <td>{{extra_branch_cost.price}}</td>
                        <td>{{((details.payment_detail.order.branches-details.payment_detail.order.plan.branches)*extra_branch_cost.price)*details.payment_detail.order.subscription}}</td>
                      </tr>
                      <tr
                        v-if="details.payment_detail.order.user>details.payment_detail.order.plan.users"
                      >
                        <td>1</td>
                        <td>Extra User Added ({{details.payment_detail.order.subscriptions.title}})</td>
                        <td>{{details.payment_detail.order.user-details.payment_detail.order.plan.users}}</td>
                        <td>{{extra_user_cost.price}}</td>
                        <td>{{((details.payment_detail.order.user-details.payment_detail.order.plan.users)*extra_user_cost.price)*details.payment_detail.order.subscription}}</td>
                      </tr>
                      <tr>
                        <td colspan="3" rowspan="6">
                          <table
                            class="table"
                            style="width:85%;border: 1px solid #DEE2E6;margin-top: 1rem;"
                            v-if="$route.params.state=='paid'"
                          >
                            <thead>
                              <tr style="border-top: 2px solid #888888;">
                                <th scope="col">Payment Method</th>
                                <th scope="col">Payment Id</th>
                                <th scope="col">Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr style="border-top: 2px solid #888888;">
                                <td>{{details.payment_detail.payment_method.title}}</td>
                                <td>SOFTINV{{details.id}}</td>
                                <td>{{details.payable_amount.toFixed(2)}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td style="padding-bottom: 0.2rem;">Sub Total</td>
                        <td>{{details.total.toFixed(2)}}</td>
                      </tr>
                      <tr class="no-border">
                        <td>Discount</td>
                        <td>{{details.discount.toFixed(2)}}</td>
                      </tr>
                      <tr class="no-border">
                        <td>Vatable Amount</td>
                        <td>{{details.vatable_amount.toFixed(2)}}</td>
                      </tr>
                      <tr class="no-border">
                        <td>VAT (13%)</td>
                        <td>{{details.vat_amount.toFixed(2)}}</td>
                      </tr>
                      <tr class="no-border">
                        <td>Total</td>
                        <td>{{details.payable_amount.toFixed(2)}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <form @submit.prevent="payBill" class="row" v-if="payment_mode">
                <div class="col-md-3" :class="{ invalid: $v.payment.paid_amount.$error }">
                  <label>Amount</label>
                  <input
                    type="text"
                    class="form-control border-radius-0"
                    placeholder="Amount"
                    @blur="$v.payment.paid_amount.$touch()"
                    v-model.trim.number="payment.paid_amount"
                    required
                  />
                  <div class="text-right" v-if="$v.payment.paid_amount.$error">
                    <p
                      class="error_level"
                      v-if="!$v.payment.paid_amount.required"
                    >Amount field is required.</p>
                    <p
                      class="error_level"
                      v-if="!$v.payment.paid_amount.numeric"
                    >Please enter numeric value.</p>
                  </div>
                  <p class="error_level" v-if="error">{{error}}</p>
                </div>
                <div class="col-md-3" v-if="payment.due_amount>0">
                  <label>Due Amount</label>
                  <input
                    type="text"
                    class="form-control border-radius-0"
                    v-model.trim.number="payment.due_amount"
                    readonly
                  />
                </div>
                <div class="col-md-3">
                  <button
                    type="submit"
                    class="btn btn-success btn-sm pl-0"
                    style="margin-top: 2rem;"
                  >Pay</button>
                  <button
                    type="button"
                    class="btn btn-danger btn-sm ml-2"
                    @click="payment_mode=false"
                    style="margin-top: 2rem;"
                  >X</button>
                </div>
              </form>
              <div class="text-right">
                <button
                  class="btn btn-primary btn-sm mr-1"
                  @click="payment_mode=true"
                  v-if="$route.params.state=='pending' && !payment_mode"
                >Pay</button>
                <button
                  class="btn btn-warning btn-sm mx-1"
                  @click="cancel('cancelled',2)"
                  v-if="$route.params.state=='pending'"
                >Cancel</button>
                <button
                  class="btn btn-danger btn-sm ml-1"
                  @click="$store.dispatch('modalClose')"
                >Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* disable eslint */
import { mapGetters } from "vuex";
import { numeric, required } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      error: "",
      details: [],
      extra_user_cost: 1,
      extra_branch_cost: 1,
      payment_mode: false,
      payment: {
        paid_amount: 0,
        due_amount: 0,
      },
    };
  },
  validations: {
    payment: {
      paid_amount: { required, numeric },
      due_amount: { required, numeric },
    },
  },
  mounted() {
    this.$store.commit("getData3", "api/cost/additional");
  },
  methods: {
    cancel(status, value) {
      this.$store.commit("setApiUrl", "api/bill/status");
      this.$store.commit("addData", {
        id: this.details.id,
        status: status,
        value: value,
      });
    },
    payBill() {
      this.$store.commit("setApiUrl", `api/bill/pay/${this.details.id}`);
      this.$store.commit("addData", this.payment);
    },
  },
  computed: {
    ...mapGetters([
      "thumb",
      "processing",
      "eventMessage",
      "dataLists",
      "dataLists3",
      "dataId",
      "modalId",
      "modalState",
    ]),
  },
  watch: {
    "payment.paid_amount"(value) {
      if (value > 0) {
        if (value > this.details.payable_amount + 1) {
          this.error = `Your bill payable amount is ${this.details.payable_amount.toFixed(
            2
          )}.`;
          this.payment.due_amount = 0;
        } else {
          this.error = "";
          this.payment.paid_amount = value;
          this.payment.due_amount =
            this.details.payable_amount.toFixed(2) - value;
        }
      } else {
        this.error = "Please enter correct amount.";
        this.payment.due_amount = 0;
      }
    },
    dataLists3(value) {
      this.extra_branch_cost = value.find(function (data) {
        return data.slug == "additional-branch";
      });
      this.extra_user_cost = value.find(function (data) {
        return data.slug == "additional-user";
      });
    },
    dataId(value) {
      this.details = this.dataLists.find(function (data) {
        return data.id == value;
      });
      this.payment.paid_amount = this.details.payable_amount.toFixed(2);
    },
    eventMessage(value) {
      if (value.indexOf("success") >= 0) {
        this.$store.commit("setRemoveList", this.details.id);
      }
    },
  },
};
</script>
<style scoped>
.modal,
.table,
div {
  font-family: Roboto;
  font-size: 16px;
}
p {
  margin-bottom: 0;
}
.line {
  height: 2px;
  background: #888888;
}

.no-border td {
  border: none;
  padding-top: 0.3rem;
  padding-bottom: 0.2rem;
}
.btn {
  min-width: 75px;
}
</style>